import React from 'react';

const NameHeader = () => (
  <div className="header">
    <h1 className="name">
      Ben
      <br />
      Lambillotte
    </h1>
  </div>
);

export default NameHeader;
